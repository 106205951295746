window.jQuery = require('jquery');
window.$ = window.jQuery;

$(function () {

	$.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });

	$('.js-submit').click(function(){
		$(this).fadeOut(200);

		// Let's validate
		var formValidate = require('./formValidate');
		if( ! formValidate.validate( $('.js-validate') ) ){
			$(this).fadeIn(200);
			return;
		}

		$('body').addClass('is-done');
		var $form = $('#form');

		$.ajax({
			url: $form.attr('action'),
			type: 'POST',
			data: {
				'name': $('.js-name').val(),
				'food': $('.js-food').val(),
				'email': $('.js-email').val(),
			},
			dataType: 'json',
			success: function(data) {
				var $formHolder = $('.form-holder');
				var $result = $formHolder.find('.result');
                console.log(data);
				$formHolder.css('min-height', $('.form-holder').outerHeight());
				$formHolder.fadeOut(200, function(){
					$formHolder.find('form').hide();

					if( data.isVip ){
						$result.html('<h2 class="title small">Tack för din anmälan.</h2><p class="copy" style="margin-top:24px;">Vi ses den 31 augusti!</p></div>');
					} else {
						$result.html('<h2 class="title small">Tyvärr, din mail finns inte med i registret.</h2><p class="copy" style="margin-top:24px;font-size:15px;">Testa igen med en annan mail eller kontakta Marie Lindgren på <a href="mailto:marie.lindgren@lindbacks.se">marie.lindgren@lindbacks.se</a> så hjälper vi dig!');
					}

					$formHolder.fadeIn(200);
				});
            },
            error: function(error) {
				console.log(error);
				var $formHolder = $('.form-holder');
				var $result = $formHolder.find('.result');
                console.log(data);
				$formHolder.css('min-height', $('.form-holder').outerHeight());
				$formHolder.fadeOut(200, function(){
					$formHolder.find('form').hide();

						$result.html('<h2 class="title small">Tack för din anmälan.</h2><p class="copy" style="margin-top:24px">Vi ses den 19 december!</p></div>');

					$formHolder.fadeIn(200);
				});
            }
		});
	});

});