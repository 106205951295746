module.exports = new function() {

	function validateFields($fields){
		var errors = [];
		$fields.removeClass('error');
		$.each($fields, function(i, val) {
			if ($(this).val() == '') {
				errors.push('error');
				$(this).addClass('error');
			}
		});

		if( errors.length > 0 ){
			return false;
		}

		return true;
	}


	return {
		validate: function($fields) {
			return validateFields($fields);
		}
	}
}